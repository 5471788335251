import "@/assets/css/bootstrap.css";
import "@/assets/css/login.css";
import "@/assets/css/About.css";
import html2canvas from "html2canvas";
import axios from "axios";
import translate from "./../translations/global.json";
import constants from "./../Constants/Constansts";
import back_logo from "./../assets/back_logo.png";
import spinner from "./../assets/spinner.gif";
import image_error_en from "./../assets/er_en.png";
import image_error_es from "./../assets/er_es.png";
import image_error_de from "./../assets/er_de.png";
import image_error_pt from "./../assets/er_pt.png";
import image_error_it from "./../assets/er_it.png";
import image_error_fr from "./../assets/er_fr.png";
import accessibilitiy_image_en from "./../assets/Accessibility-settings_En.png";
import accessibilitiy_image_de from "./../assets/Accessibility-settings_De.png";
import accessibilitiy_image_es from "./../assets/Accessibility-settings_Es.png";
import accessibilitiy_image_fr from "./../assets/Accessibility-settings_Fr.png";
import accessibilitiy_image_it from "./../assets/Accessibility-settings_It.png";
import accessibilitiy_image_pt from "./../assets/Accessibility-settings_Pt.png";
import CloseAccessibilityMessageIcon from "./../assets/CloseAccessibilityMessageIcon.png"

export default {
    name: "about",
    data: function() {
        return {
            screen_cast: "Screen Cast",
            color: "#673AB7",
            back_logo: back_logo,
            isloading: true,
            please_wait: "Loading preview...",
            view_screen: "View your mobile screen on your PC, Mac, Tablet or Smart TV",
            SCREEN_STREAM_ADDRESS: "",
            heightscreen: "",
            widthscreen: "",
            error: spinner,
            spinner_view: spinner,
            back: "BacK",
            home: "Home",
            switch_task: "Switch Task",
            Logout: "Logout",
            screenshot: "Screenshot",
            choose_background: null,
            tooltip:"",
            TooltipLeft:'200px',
            TooltipTop:'-100px',
            TooltipBottom:'auto',
            accessibilityMessage:"",
            accessibilityVideo:"",
            accessibilityVideoLink:"https://deskshare.com/tutorials/sc/Screen_Cast_Accessibility_Permission.mp4",
            CloseAccessibility:CloseAccessibilityMessageIcon,
            ShowAccessibilityMessage:false,
            IsClosedAccessibilityMsgButtonClicked:false,
            IsPreviewLoaded:false,
            IsNavigationPresent:false,
            IsScreenResized:false,
            oldeversiontext: "Please update to the latest version of \"Screen Cast\".",
        };
    },
    mounted: function() {
        let canvasElem = document.getElementById("mj");

        var detectedEvent;

        var THRESHOLD = 25;
        var x, y, x1, y1;

        var a = 0;
        var b = 0;
        var mouseDownTime;
        var recordedTime = new Date().getTime();

        document.getElementById("mj").addEventListener(
            "mousedown",
            function(a) {
                localStorage.setItem("click", 0);
                let rect = canvasElem.getBoundingClientRect();
                mouseDownTime = new Date().getTime();
                50 < new Date().getTime() - recordedTime &&
                    ((x = a.clientX - rect.left),
                        (y = a.clientY - rect.top),
                        (recordedTime = new Date().getTime()));
            }, !1
        );

        document.getElementById("mj").addEventListener(
            "mouseup",
            function(a) {
                localStorage.setItem("click", 0);
                let rect = canvasElem.getBoundingClientRect();
                x1 = x;
                y1 = y;
                x = a.clientX - rect.left;
                y = a.clientY - rect.top;
                getMousePosition(canvasElem);
            }, !1
        );

        document.getElementById("mj").addEventListener(
            "touchstart",
            function(a) {
                localStorage.setItem("click", 0);
                let rect = canvasElem.getBoundingClientRect();
                mouseDownTime = new Date().getTime();
                50 < new Date().getTime() - recordedTime &&
                    ((x = a.clientX - rect.left),
                        (y = a.clientY - rect.top),
                        (recordedTime = new Date().getTime()));
                getMousePosition(canvasElem);
            }, !1
        );

        document.getElementById("mj").addEventListener(
            "touchend",
            function(a) {
                localStorage.setItem("click", 0);
                let rect = canvasElem.getBoundingClientRect();
                x1 = x;
                y1 = y;
                x = a.clientX - rect.left;
                y = a.clientY - rect.top;
                getMousePosition(canvasElem);
            }, !1
        );

        function getMousePosition(img) {
            localStorage.setItem("click", 0);
            a = x - x1;
            b = y - y1;
            if (!(parseInt(Math.sqrt(a * a + b * b), 10) < THRESHOLD)) {
                if (x1 - x > Math.abs(y - y1)) {
                    detectedEvent = "left";
                }
                if (x - x1 > Math.abs(y - y1)) {
                    detectedEvent = "right";
                }
                if (y1 - y > Math.abs(x - x1)) {
                    detectedEvent = "up";
                }
                if (y - y1 > Math.abs(x - x1)) {
                    detectedEvent = "down";
                }
            } else {
                detectedEvent = "click";
            }

            recordedTime = new Date().getTime();
            var diff = recordedTime - mouseDownTime;
            mouseDownTime = 0;
            let wid = img.width;
            let hei = img.height;
            x = (x / wid) * 100;
            y = (y / hei) * 100;

            x1 = (x1 / wid) * 100;
            y1 = (y1 / hei) * 100;
            var requestData =
                "x=" +
                x +
                "&y=" +
                y +
                "&x1=" +
                x1 +
                "&y1=" +
                y1 +
                "&duration=" +
                diff +
                "&event=" +
                detectedEvent;

            var flashCommand = {
                command: "touch",
                data: requestData,
            };


            axios(constants.URL + "/touch", {
                method: "POST",
                headers: {
                    Authorization: `${localStorage.getItem(constants.TOKEN)}`,
                    "Content-Type": "application/json",
                },
                url: constants.URL + "/touch",
                data: flashCommand,
            })
                .then(() => {})

                .catch(() => {});
        }
        var mj=document.getElementById("mj")
        mj.onload=function(){ 
            var diffrence = window.innerWidth - document.getElementById("mj").clientWidth;
            if(diffrence < 200) {
                document.getElementsByClassName("right-flex-div")[0].style.display = "none";
                this.IsScreenResized=true;
            }
            else {
                document.getElementsByClassName("right-flex-div")[0].style.display = "block";
            }
        }
        if(localStorage.getItem("IsNavigationPresent")){
            this.IsNavigationPresent=true;
        }
          
    },

    created: function() {
        this.performNetworkTest();
        if(localStorage.getItem("isAndroid13")){
            this.accessibilityVideoLink='https://www.deskshare.com/tutorials/sc/Screen_Cast_Accessibility_Permission_Android_13.mp4'
        }
        document.addEventListener('keydown', function(e) {
            if (e.ctrlKey && e.key == 'r') {
                e.preventDefault();
                e.stopPropagation();
            }
            if (e.ctrlKey && e.key == 'a') {
                e.preventDefault();
                e.stopPropagation();
                let flashCommand = {
                    command: "keyboard",
                    data:"selectAll"
                };
                sendCommand(flashCommand, "keyboard");
            }
            if (e.ctrlKey && e.key == 'c') {
                e.preventDefault();
                e.stopPropagation();
                let flashCommand = {
                    command: "keyboard",
                    data:"copy"
                };
                sendCommand(flashCommand, "keyboard");
            }
            if (e.ctrlKey && e.key == 'v') {
                e.preventDefault();
                e.stopPropagation();
                let flashCommand = {
                    command: "keyboard",
                    data:"paste"
                };
                sendCommand(flashCommand, "keyboard");
            }
            if (e.ctrlKey && e.key == 'x') {
                e.preventDefault();
                e.stopPropagation();
                let flashCommand = {
                    command: "keyboard",
                    data:"cut"
                };
                sendCommand(flashCommand, "keyboard");
            }
            if (e.which==9) {
                e.preventDefault();
                e.stopPropagation();
            }
        }, false);
        document.addEventListener('keyup', function(e) {
            console.log(e.key);
            if (e.key=='Tab') {
                e.preventDefault();
                e.stopPropagation();
                let flashCommand = {
                    command: "keyboard",
                    data:"Tab"
                };
                sendCommand(flashCommand, "keyboard");
            }
            else if(!e.ctrlKey){
                var flashCommand = {
                    command: "keyboard",
                    data:e.key
                };
                sendCommand(flashCommand, "keyboard");
            }
        }, false);

        function sendCommand(currentCommand, whichapi) {

            axios(constants.URL + "/" + whichapi, {
                method: "POST",
                headers: {
                    Authorization: `${localStorage.getItem(constants.TOKEN)}`,
                    "Content-Type": "application/json",
                },
                url: constants.URL + "/" + whichapi,
                data: currentCommand,
            })
                .then(result => {

                    console.log("show error response request li " + result.request.status);

                })
        }

        if (localStorage.getItem(constants.TOKEN) == null) {
            this.logout();
        }

        var evtSource = new EventSource(
            constants.URL +
            "/videofeed?ver=" +
            localStorage.getItem(constants.TOKEN) +
            "&sse=true"
        );
        
        evtSource.addEventListener(
            "message",
            (event) => {
                if (localStorage.getItem(constants.TOKEN) == null) {
                    localStorage.clear()
                    window.location="/"
                }
                var accesibilitycheck = event.data.substring(event.data.indexOf("accessibility") + 14);
                if (accesibilitycheck == "true") {
                    localStorage.setItem("accessibility", 1);
                    localStorage.setItem("click", 0);
                } else {
                    localStorage.setItem("accessibility", 0)
                }

                if (localStorage.getItem("click") == 1) {

                    var checkLang = localStorage.getItem(constants.LANGUAGE_CODE);
                    if (checkLang == 0) {
                        this.tooltip=accessibilitiy_image_en;
                    } else if (localStorage.getItem(constants.LANGUAGE_CODE) == 1) {
                        this.tooltip=accessibilitiy_image_de;
                    } else if (localStorage.getItem(constants.LANGUAGE_CODE) == 2) {
                        this.tooltip=accessibilitiy_image_fr;
                    } else if (localStorage.getItem(constants.LANGUAGE_CODE) == 3) {
                        this.tooltip=accessibilitiy_image_es;
                    } else if (localStorage.getItem(constants.LANGUAGE_CODE) == 4) {
                        this.tooltip=accessibilitiy_image_pt;
                    } else if (localStorage.getItem(constants.LANGUAGE_CODE) == 5) {
                        this.tooltip=accessibilitiy_image_it;
                    }
                } else {
                    var diffrence = window.innerWidth - document.getElementById("mj").clientWidth;
                    if(diffrence < 200) {
                        document.getElementsByClassName("right-flex-div")[0].style.display = "none";
                        this.IsScreenResized=true;
                    }
                    else {
                        document.getElementsByClassName("right-flex-div")[0].style.display = "block";
                        this.IsScreenResized=false;
                    }
                    if(event.data.includes("accessibility")){
                        var stream_addres = event.data.substring(0, event.data.indexOf("accessibility"));
                        this.SCREEN_STREAM_ADDRESS = stream_addres;
                        this.IsPreviewLoaded=true;
                        var accesibility = event.data.substring(event.data.indexOf("accessibility") + 14);

                        if (accesibility == "true") {
                            localStorage.setItem("accessibility", 1);
                            localStorage.setItem("click", 0);
                            this.tooltip="";
                            this.ShowAccessibilityMessage=false;
                        } else {
                            localStorage.setItem("accessibility", 0)
                            this.ShowAccessibilityMessage=true;
                        }
                    }
                    else{
                        this.SCREEN_STREAM_ADDRESS=event.data;
                        this.IsPreviewLoaded=true;
                    }

                    this.isloading = false;
                }
            },
            false,

        );
        evtSource.addEventListener("error", () => {
            
            this.isloading = false;
            this.IsPreviewLoaded=false;
            axios.get(constants.URL +
                "/videofeed?ver=" +
                localStorage.getItem(constants.TOKEN)
            )
            .catch((err) => {
                if (err.response.status === 401) {
                    localStorage.removeItem(localStorage.getItem(constants.TOKEN));
                    localStorage.removeItem(constants.TOKEN);
                    this.$router.push("/");
                }
                else if(err.response.status === 400){
                    var checkLang = localStorage.getItem(constants.LANGUAGE_CODE);
                    if (checkLang == 0) {
                        this.SCREEN_STREAM_ADDRESS = image_error_en;

                    } else if (localStorage.getItem(constants.LANGUAGE_CODE) == 1) {
                        this.SCREEN_STREAM_ADDRESS = image_error_de;

                    } else if (localStorage.getItem(constants.LANGUAGE_CODE) == 2) {
                        this.SCREEN_STREAM_ADDRESS = image_error_fr;

                    } else if (localStorage.getItem(constants.LANGUAGE_CODE) == 3) {
                        this.SCREEN_STREAM_ADDRESS = image_error_es;

                    } else if (localStorage.getItem(constants.LANGUAGE_CODE) == 4) {
                        this.SCREEN_STREAM_ADDRESS = image_error_pt;

                    } else if (localStorage.getItem(constants.LANGUAGE_CODE) == 5) {
                        this.SCREEN_STREAM_ADDRESS = image_error_it;

                    }
                    this.ShowAccessibilityMessage=false;
                }
            });
        })

        var langCode = localStorage.getItem(constants.LANGUAGE_CODE);
        this.please_wait = translate.Loadingpreview[langCode];
        this.view_screen = translate.CastyourmobilescreentoyourPCMacTabletorSmartTV[langCode];
        this.home = translate.Home[langCode];
        this.back = translate.Back[langCode];
        this.switch_task = translate.switch_task[langCode];
        this.screenshot = translate.screenshot[langCode];
        this.Logout = translate.Logout[langCode];
        this.choose_background = translate.choose_background[langCode]
        this.accessibilityVideo=translate.WatchVideoT[langCode]
        this.accessibilityMessage=translate.AccessibilityMessage[langCode]

        localStorage.setItem("reloadonce", 0);
        window.onresize = window.onload = function () {
            var diffrence = window.innerWidth - document.getElementById("mj").clientWidth;
            if(diffrence < 200) {
                document.getElementsByClassName("right-flex-div")[0].style.display = "none";
                this.ShowAccessibilityMessage=false;
            }
            else {
                document.getElementsByClassName("right-flex-div")[0].style.display = "block";
                this.ShowAccessibilityMessage=true;
            }
        }
        window.onload = function () {
            var mj=document.getElementById("mj")
            mj.onload=function(){
                var diffrence = window.innerWidth - document.getElementById("mj").clientWidth;
                if(diffrence < 200) {
                    document.getElementsByClassName("right-flex-div")[0].style.display = "none";
                }
                else {
                    document.getElementsByClassName("right-flex-div")[0].style.display = "block";
                }
            }
        }
        if (localStorage.getItem("version")) {
            this.oldeversiontext = "";
        }
    },
    components: {},
    methods: {

        IsFullScreen() {
            return !!(
                document.fullscreenElement ||
                document.mozFullScreenElement ||
                document.webkitFullscreenElement ||
                document.msFullscreenElement
            );
        },
        handletouches(event) {
            if(localStorage.getItem("accessibility")==0 && this.IsPreviewLoaded && !this.IsScreenResized){
                var checkLang = localStorage.getItem(constants.LANGUAGE_CODE);
                if (checkLang == 0) {
                    this.tooltip=accessibilitiy_image_en;
                } else if (localStorage.getItem(constants.LANGUAGE_CODE) == 1) {
                    this.tooltip=accessibilitiy_image_de;
                } else if (localStorage.getItem(constants.LANGUAGE_CODE) == 2) {
                    this.tooltip=accessibilitiy_image_fr;
                } else if (localStorage.getItem(constants.LANGUAGE_CODE) == 3) {
                    this.tooltip=accessibilitiy_image_es;
                } else if (localStorage.getItem(constants.LANGUAGE_CODE) == 4) {
                    this.tooltip=accessibilitiy_image_pt;
                } else if (localStorage.getItem(constants.LANGUAGE_CODE) == 5) {
                    this.tooltip=accessibilitiy_image_it;
                }
                setTimeout(() => {
                    this.tooltip="";
                }, 10000);
            }
            else{
                this.tooltip="";
            }
            var x = event.pageX;
            var y = event.pageY;
            this.TooltipLeft=x+'px';
            if(event.offsetY<100){
                this.TooltipTop=event.pageY+'px';
                this.TooltipBottom='auto';
            }
            else{
                this.TooltipBottom=(window.innerHeight-y)+'px';
                this.TooltipTop='auto';
            }
            var img = document.getElementById("mj");
            var width = img.naturalWidth;
            var height = img.naturalHeight;
            if (width === 1367 && height === 768) {
                window.location.reload(true);
            }
        },

        logout() {
            var flashCommand = {
                command: "stop",
            };
            this.sendCommand(flashCommand, "stop");
            localStorage.removeItem(constants.TOKEN);
            this.$router.push("/");
        },

        openFullscreen() {
            var elem = this.$refs["fullscreen"];
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            }
        },

        takeScreenShot() {
            html2canvas(this.$refs.fullscreen, {
                backgroundColor: "#ffffff",
            }).then((canvas) => {
                var imgData = canvas.toDataURL("image/jpeg");
                this.fileDownload(imgData);
            });
        },

        fileDownload(downloadUrl) {
            let aLink = document.createElement("a");
            aLink.style.display = "none";
            aLink.href = downloadUrl;
            aLink.download = "ScreenCast.png";
            document.body.appendChild(aLink);
            aLink.click();
            document.body.removeChild(aLink);
        },

        onBackPress(event) {
            var x = event.pageX;
            var y = event.pageY;
            this.TooltipLeft=x+'px';
            this.TooltipTop=(y-100)+'px';
            var flashCommand = {
                command: "back",
            };
            this.sendCommand(flashCommand, "back");

        },

        onHomeButton(event) {
            var x = event.pageX;
            var y = event.pageY;
            this.TooltipLeft=x+'px';
            this.TooltipTop=(y-100)+'px';
            var flashCommand = {
                command: "home",
            };
            this.sendCommand(flashCommand, "home");

        },
        onRecentButton(event) {
            var x = event.pageX;
            var y = event.pageY;
            this.TooltipLeft=x+'px';
            this.TooltipTop=(y-100)+'px';
            var flashCommand = {
                command: "recent",
            };
            this.sendCommand(flashCommand, "recent");

        },

        updatetheme(colorName) {
            document.body.style.backgroundColor = colorName;
            document.getElementById("colorDropId").style.backgroundColor = colorName;
        },

        sendCommand(currentCommand, whichapi) {

            axios(constants.URL + "/" + whichapi, {
                method: "POST",
                headers: {
                    Authorization: `${localStorage.getItem(constants.TOKEN)}`,
                    "Content-Type": "application/json",
                },
                url: constants.URL + "/" + whichapi,
                data: currentCommand,
            })
                .then(result => {

                    console.log("show error response request li " + result.request.status);

                })
            setTimeout(function() {
                if (localStorage.getItem("accessibility") == 0) {

                    localStorage.setItem("click", 1)
                }
            }, 2000);


        },
        performNetworkTest() {
            axios
                .get(
                    constants.URL +
                    "/networktest?ver=" +
                    localStorage.getItem(constants.TOKEN)
                )
                .then(() => {
                    console.log("good network");
                })
                .catch((error) => {
                    this.isloading = true;
                    var errorMessage = error.response.data.message;
                    var errorStatusCode = error.response.status;
                    var err1 =
                        "Screen Cast is not connected. Please start Screen Cast on mobile device and try again.";
                    if (errorMessage == err1 && errorStatusCode == 400) {
                        this.please_wait =
                            translate.PleasestartScreenCastonmobiledeviceandtryagain[
                            localStorage.getItem(constants.LANGUAGE_CODE)
                            ];
                    }
                    if (errorStatusCode == 401) {
                        localStorage.removeItem(constants.TOKEN);
                        this.$router.push("/");
                    }
                });
        },
    },
};