<template>
  <div class="" style="">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
    <p id="oldversionmsg" style="margin-bottom: 0px;margin-top: 5px;width: 100%;text-align: center;color: red;font-size: calc(0.8vw + 0.1vh + 1vmin);">
      {{ oldeversiontext }}
    </p>
    <p id="accessibilitymsg" style="font-size: calc(1vw + 0.1vh + 0.4vmin);text-align: center;font-family: Arial, Helvetica, sans-serif;border: 0.5px solid grey;padding: 5px 50px;margin: 5px auto;border-width: 0.5px;width:fit-content;position:relative" :style="ShowAccessibilityMessage && !IsClosedAccessibilityMsgButtonClicked && !IsScreenResized ? { 'display': 'block' } : { 'display': 'none' }">{{ accessibilityMessage }} <a :href="accessibilityVideoLink" target="_blank" style="color: #0d6efd;">{{ accessibilityVideo }}</a><img src="./../assets/CloseAccessibilityMessageIcon.png" id="CloseAccessibiltyMsg" alt="" style="cursor: pointer;float: right;width:calc(1.5vw + 0.1vh + 0.4vmin);position:absolute;top:20%;right:5px" @click="ShowAccessibilityMessage=false;IsClosedAccessibilityMsgButtonClicked=true"></p>
    <img :src="tooltip" alt="" class="AccessibilityTooltip" style="position:absolute;z-index:1000;width:calc(20vw + 0.1vh + 0.4vmin)" :style="{'top':TooltipTop,'bottom' : TooltipBottom,'left':TooltipLeft }" >
    <div style="display: flex;position:relative">
      <div
        class="d-flex justify-content-around d-flex-mj"
        style="
          left: 10%;
          right: 10%;
          display: flex;
          justify-content: space-around;

          margin: auto;
          border-radius: 6px;
          padding: calc(0.5vw + .1vh + .4vmin);

          margin-left: 520px;
        "
      >
        <div class="left-flex-div"></div>

        <div style="text-align: center; width:33% display:none;">
          <img
            :src="spinner_view"
            v-show="isloading"
            id="mj1"
            style="
              height: 50px;
              width: 50px;
              margin-top: 220px;
              margin-bottom: 10px;
            "
          />

          <div v-show="isloading" style="margin-bottom: 160px">
            <span v-show="isloading" id="mj2">{{ please_wait }}</span>
          </div>
        </div>
        <img
          id="mj"
          :src="SCREEN_STREAM_ADDRESS"
          v-show="!isloading"
          @click="handletouches"
          style="
            max-width: 74vw !important;
            width: auto;
            max-height: 90vh;
            height: calc(38vw + .1vh + .4vmin);
          "
        />
        <div class="right-flex-div">
          <ul
            style="
              list-style: none !important;
              position: absolute;
              right: 2vw;
            "
          >
            <li class="listbtn">
              <button
                @click="logout()"
                style="
                      min-width: calc(7vw + 0.1vh + 0.4vmin);
                      font-size: calc(1vw + .1vh + .4vmin);
                      background-color: rgb(0, 51, 102);
                      color: rgb(249, 249, 249);
                      border: none;
                      padding: calc(0.2vw + .1vh + .4vmin);
                "
              >
                {{ Logout }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="NavigationButtons"
      :style="IsPreviewLoaded && IsNavigationPresent?{ 'display':'flex' }:{'display':'none'}"
    >
      <div class="" id="backId" @click="onBackPress">
        <i
          class="fa fa-arrow-left"
          style="
            font-size: calc(1.5vw + .1vh + .4vmin);
            color: white;
            padding-left: 2px;
          "
        ></i>
      </div>

      <div class="" id="openHomeId" @click="onHomeButton">
        <img
          style="margin-top: 0px;width:calc(1.8vw + .1vh + .4vmin)"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFEAAABRCAYAAACqj0o2AAAACXBIWXMAAAsSAAALEgHS3X78AAACuklEQVR4nO3d4U0jMRCG4QHd/0sLVECugoMO6IBQASmB6yDXAXRACaED6AA6gAqMLHkiXeLb9dif7dndeaX8YgXWIyferLzLmXOOrLLOza88QwT0Q/HYrohoTUQrItoT0SsRfSoY12n+M1HZa+uc+3Txds65lbYxa1tYHonoduSYtzBL1cxKTZ+JKYC+y/D2XjUYU1JaEFMBOVWQGhClgJwayN6IuYCcCsieiKWAXHfIXogoQK4rZA9ENCDXDbI1Yi1ArgtkS8TagFxzyFaIrQC5ppAtEFsDcs0gayP2AuSaQNZE7A3IVYeshagFkKsKWQNRGyBXDRKNqBWQqwKJRNQOyMEhUYhTAeSgkAjEqQFyMMhSxKkCchDIEsSpA3LFkLmIcwHkiiBzEOcGyGVDShHnCshlQUoQ5w7IiSFTEZcCyIkgUxCXBsglQ44hLhWQS4IcQlw6IDcK+T9EA/y3QcgY4tYAo3nI59gPYvsT/b6/n12GOY2uw6w8dDwTrwxwtJvjA2KI1nDrMUQro2PEd0Mc7WSv+DHiPu33LLqTFTo2E1+WrjTQVwqibxMOtuI2o29nCrPRr9IfhnjIT6o7yck2twrfXm7C2foS47fvw9CiK72jyi88vxeAeSY52M4TARkiIEMEZIiADBGQIQLShOhvBv8VTi+GXhdE9FfBeA9peQbEh+Bu+vfwJcB3X3lcSWmZiQ8ZjyPYVRqLOC2IOdcx1Vz7tIUFkCECMkRAhgjIEAEZIiBDBGSIgAwRkCECMkRAUkSdT9LsnBTxdXYCp4m30UgRozsAZpZ4ouTMxLeZI4qvU+YsLNuEY6ban5zrlDmI+7C5Z249hSvs4krud74G7hzLvUqN2Ev5FWbgJvcXIB4RvQk7x3LvXN8VLFjrMHty//ZzmBBFp272DxwA2TcWQIYIyBBLI6JvPK2FDU+C7usAAAAASUVORK5CYII="
          class=""
          alt=""
        />
      </div>

      <div class="" id="openRecentId" @click="onRecentButton">
        <img
          style="margin-top: 0px;width:calc(1.8vw + .1vh + .4vmin)"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFEAAABRCAYAAACqj0o2AAAACXBIWXMAAAsSAAALEgHS3X78AAACGklEQVR4nO3d3VHCQBiF4aPjPenA2AEdGCuQDqQE7UA7sAO1A6hA0gF2gB3ECnAiywyz2d1sksNI2PNch0zyzuYHLj4uttstZJhL9RtOEQmuBuxiCiADUJzM2fSzBlABWPXdQdeIdbhHADMAkyOc0H9bAlgAeO9yHLEPltzs+HaMZXr4BjCPXZ0x98S5WfKpBKxdA/gE8BqzcdtKrAO+0Q5tnD5MB6/QSlTAnYe2e6RvJebmEj7Hh0dfT77L2xdxldg9MMaPWVyVva3rci4U0GliXu8aXBGDN9HERUecpV4qYOL6hmZHLPQwadUaMT+N4zxpU/vgFLG7zP6EfgojUEQCRSRQRAJFJFBEAkUkUEQCRSRQRAJFJFBEAkUkUEQCRSRQRAJFJFBEAkUkUEQCRSRQRAJFJFBEAkUkUEQCRSRQRAJFJFBEAkUkUEQCRSRQRAJFJFBEAkUkUEQCRSRQRAJFJFBEAkUkUEQCRSRQRAJFJFBEAjti78ltCVnbp2pHbGwgDa0R68E5X+oW1LhaXffETrMDE1MC2Nin7BpzlZkNNdql6S52JVa+SUSJW/oevKH5ifVY0PvUyxneYWuImJ+oh8wuYOELiJaIlflweZxjG4V9wOCrX9s3ln3Il7Ta/SkPhnEGxX7tewZwY+arnrvSPIWDl/ChPv89kJnxgFPX8LGR2pgVt3C9Bx4jolj0Kw6BIhIo4lAAfgFwP1W3cwxfmAAAAABJRU5ErkJggg=="
          class=""
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script src="./../js/About.js"></script>
